div#root a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

div#root {
  font-size: 16px;
  font-weight: 0em;
  letter-spacing: 0em;
  line-height: normal;
}

div#root li {
  margin-bottom: 0px;
}

div#root .web-chat {
  overflow: hidden;
}

div#root .connect-spinner {
  display: flex;
  margin: auto;
  text-align: center;
}

div#root .connect-spinner > .content {
  margin: auto;
}

div#root .connect-spinner > .content > .icon {
  font-size: 64px;
}

div#root .ac-media-playButton-arrow {
  width: 12px;
  height: 15px;
  border-width: 24.5px 0px 24.5px 43px !important;
  border-style: solid;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  transform: translate(1.2px, 0px);
  box-sizing: border-box;
}