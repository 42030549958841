/*
div#root html {
  color: -internal-root-color;
}
*/

/*
div#root div {
  display: block;
}
*/

div#root button {
  writing-mode: horizontal-tb !important;
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: buttontext;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-align: center;
  cursor: default;
  font: 400 13.3333px Arial;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 400;
  font-stretch: normal;
  font-size: 13.3333px;
  line-height: normal;
  font-family: Arial;
}

div#root .minimizable-web-chat > button.maximize {
  background-color: #39C;
  border-radius: 50%;
  border: 2px solid #39C;
  bottom: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  color: White;
  font-size: 30px;
  height: 64px;
  outline: 0;
  padding: 15px;
  position: fixed;
  right: 20px;
  width: 64px;
  z-index: 10;
}

div#root .minimizable-web-chat > button.maximize > .red-dot {
  color: #EF0000;
  font-size: 14px;
  position: absolute;
  right: 12px;
  top: 12px;
}

div#root .minimizable-web-chat > button.maximize:focus:not(:active),
div#root .minimizable-web-chat > button.maximize:hover:not(:active) {
  background-color: #069;
  border-color: #069;
}

div#root .minimizable-web-chat > button.maximize:active {
  background-color: White;
  color: #39C;
}

div#root .minimizable-web-chat > .chat-box {
  background-color: rgba(255, 255, 255, .8);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  border: 4px solid #39C;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  min-width: 320px;
  position: fixed;
  top: 20px;
  width: 30%;
  z-index: 9;
  text-align: left;
}

div#root .minimizable-web-chat > .chat-box.left {
  left: 20px;
}

div#root .minimizable-web-chat > .chat-box.right {
  right: 20px;
}

div#root .minimizable-web-chat > .chat-box > header {
  background-color: #39D;
  height: 40px;
  display: flex;
}

div#root .minimizable-web-chat > .chat-box > header > .filler {
  flex: 0 10000 100%;
}

div#root .minimizable-web-chat > .chat-box > header > button {
  width: 40px;
  height: 40px;
  background-color: Transparent !important;
  border: 0;
  color: White;
  outline: 0;
}

div#root .minimizable-web-chat > .chat-box > header > button:focus:not(:active),
div#root .minimizable-web-chat > .chat-box > header > button:hover:not(:active) {
  color: rgba(255, 255, 255, .6);
}

div#root .minimizable-web-chat > .chat-box > header > button:focus:active {
  color: rgba(255, 255, 255, .4);
}

div#root .minimizable-web-chat > .chat-box > .react-web-chat {
  flex: 1;
}

div#root .avatar {
  position: absolute;
}
div#root .avatar.left {
  left: -105px;
  bottom: 10px;
}
div#root .avatar.right {
  right: -105px;
  bottom: 10px;
}
div#root .avatar.left.ian {
  left: -305px;
  bottom: 10px;
}
div#root .avatar.right.ian {
  right: -305px;
  bottom: 10px;
}

@supports (-ms-accelerator:true) {
  /* Edge 12+ CSS */ 
  div#root .minimizable-web-chat > .chat-box > header > button {
    background-color: #39d;
  }

  div#root .minimizable-web-chat > .chat-box a:link {
    color: #0000FF;
  }

  div#root .minimizable-web-chat > .chat-box a:visited {
    color: #800080;
  }

  div#root .minimizable-web-chat > .chat-box a:active {
    color: #FF0000;
  } 

  div#root .minimizable-web-chat > .chat-box > .react-web-chat.connect-spinner {
    flex: 0;
  }

  div#root .minimizable-web-chat > .chat-box {
    background-color: aliceblue;
  }
}

@supports (-ms-ime-align:auto) {
  /* Edge 16+ CSS */ 
  div#root .minimizable-web-chat > .chat-box > header > button {
    background-color: #39d;
  }

  div#root .minimizable-web-chat > .chat-box a:link {
    color: #0000FF;
  }

  div#root .minimizable-web-chat > .chat-box a:visited {
    color: #800080;
  }

  div#root .minimizable-web-chat > .chat-box a:active {
    color: #FF0000;
  } 

  div#root .minimizable-web-chat > .chat-box > .react-web-chat.connect-spinner {
    flex: 0;
  }

  div#root .minimizable-web-chat > .chat-box {
    background-color: aliceblue;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS */
  div#root .minimizable-web-chat > .chat-box > header > button {
    background-color: #39d;
  }

  div#root .minimizable-web-chat > .chat-box a:link {
    color: #0000FF;
  }

  div#root .minimizable-web-chat > .chat-box a:visited {
    color: #800080;
  }

  div#root .minimizable-web-chat > .chat-box a:active {
    color: #FF0000;
  } 

  div#root .minimizable-web-chat > .chat-box > .react-web-chat.connect-spinner {
    flex: 0;
  }

  div#root .minimizable-web-chat > .chat-box {
    background-color: aliceblue;
  }
}

div#root .minimizable-web-chat > .chat-box .robot-msg > div > span,
div#root .minimizable-web-chat > .chat-box .robot-msg > p {
  color: #000;
}
